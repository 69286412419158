import React, { useState } from "react";
import { Formik, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import AuthService from "../../services/auth.service";
import Modal from "bootstrap/js/dist/modal";
import logoForm from "../../assets/images/logo-form.png";

import { EMAIL_REGEX, MOBILE_REGEX, NOSPACE, NOSPACE_REGEX, PASSWORDS_REGEX } from "../../utils/Constants";
const Register = () => {
  const navigate = useNavigate();

  let initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmpassword: "",
    mobile: "",
    
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required").trim(NOSPACE),
    last_name: Yup.string().required("Last name is required").trim(NOSPACE),
    email: Yup.string()
      .email()
      .max(255)
      .required("email is required")
      .trim(NOSPACE),
    password: Yup.string().required("Password is required").matches(PASSWORDS_REGEX, "Must be minimum 8  characters long and alphanumeric with at least 1 uppercase letter, 1 number and 1 special character"),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    mobile: Yup.string()
      .matches(
        MOBILE_REGEX,
        "Phone number should be 0-9 of 10 digits with country code"
      )
      .required("Mobile is required")
      .min(10, "Phone number should be 0-9 of 10 digits with country code")
      .max(16, "Phone number should be 0-9 of 10 digits with country code"),
   
  });

  const onSubmit = async (values) => {
   console.log(values);
    try {
      const { accept, confirmpassword, ...rest } = values;
      const response = await AuthService.register(rest);
      const {  status, message } = response.data;
      if (status==1) {
      
       
        toast.info(message);
        navigate("/login");
      } else {
       
        toast.info(message);
      }
     
    } catch (error) {
      const { message } = error.response.data;
      toast.error(message);
    }
  };

  return (
    <>
      <section
        className="bannerimg cover-image bg-background3"
        data-image-src="https://qcbiztech.in/fdworld/public/web/images/banners/slide-banner.jpg"
      >
        <div className="header-text mb-0">
          <div className="container">
            <div className="row text-white">
              <div className="col">
                <h1 className="">Register</h1>
              </div>
              <div className="col col-auto">
                <ol className="breadcrumb text-center">
                  <li className="breadcrumb-item">
                    <a>Home</a>
                  </li>

                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    Register
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sptb">
        <div className="container customerpage">
          <div className="row">
            <div className="single-page">
              <div className="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
                <div className="wrapper wrapper2 text-left">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="card-body"
                        tabIndex="500"
                      >
                        <h4 className="font-weight-semibold2 pb-4">
                          Register new account
                        </h4>
                        <div className="mail">
                          <input
                            placeholder="First Name"
                            type="text"
                            name="first_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                          />
                          <p className="text-danger">
                            {errors.first_name && touched.first_name && errors.first_name}
                          </p>

                          <label>First Name</label>
                        </div>
                        <div className="mail">
                          <input
                            placeholder="Last Name"
                            type="text"
                            name="last_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.last_name}
                          />
                          <p className="text-danger">
                            {errors.last_name && touched.last_name && errors.last_name}
                          </p>

                          <label>Last Name</label>
                        </div>
                        <div className="mail">
                          <input
                            placeholder="Mobile Number"
                            type="number"
                            name="mobile"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mobile}
                          />
                          <p className="text-danger">
                            {errors.mobile && touched.mobile && errors.mobile}
                          </p>

                          <label>Mobile Number</label>
                        </div>
                        <div className="mail">
                          <input
                            placeholder="Email Address"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <p className="text-danger">
                            {errors.email && touched.email && errors.email}
                          </p>

                          <label>Email Address</label>
                        </div>

                        <div className="passwd">
                          <input
                            placeholder="Password"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <p className="text-danger">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>

                          <label>Password</label>
                        </div>
                        <div className="passwd">
                          <input
                            placeholder="Confirm Password"
                            type="password"
                            name="confirmpassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmpassword}
                          />
                          <p className="text-danger">
                            {errors.confirmpassword &&
                              touched.confirmpassword &&
                              errors.confirmpassword}
                          </p>

                          <label>Confirm Password</label>
                        </div>
                        
                        <div className="submit">
                          <button
                            className="btn btn-primary btn-block"
                            disabled={isSubmitting}
                          >
                            Register
                          </button>
                        </div>
                        <div className="row">
                          <div className="col">
                            <p className="text-dark mb-0 fs-12">
                              Already have an account?
                              <Link to={"/login"}><a className="text-primary ml-1">Login</a></Link>
                            </p>
                          </div>
                          <div className="col col-auto">
                            <p className="mb-0 mt-0 fs-12">
                              <a>Forgot Password</a>
                            </p>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>

                  {/* <hr className="divider" />
                  <div className="card-body text-center">
                    <ul className="mb-0 login-social-icons">
                      <li className="btn-facebook">
                        <a className="social-icon">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="btn-twitter">
                        <a className="social-icon">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li className="btn-google">
                        <a className="social-icon">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
