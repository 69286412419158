import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UserService from "../services/user.service";
const Mybookings = () => {
  const [bookingData, setbookingData] = useState([]);
  const [page,setPage] = useState(1);
  const [pageArr,setPageArr] = useState([]);
  useEffect(() => {
		var value = page;
		UserService.myConcertBookings(value)
			.then((res) => {
				if (res.data.status == 1) {
         let PagArr = [];
          for (let index = 0; index < res.data.data.last_page; index++) {
            PagArr.push(index);
            
          }
          console.log(res.data.data.data,"res.data.data.data");
          setPageArr(PagArr);
					setbookingData(res.data.data.data)
				} else {
					setbookingData("")
				}
			})
			.catch((err) => {
        console.log(err)
				const { message } = err.response.data;

			});
	}, [,page]);
  return (
    <>
  
      <section className="sptb">
        <div className="container">
          <div className="section-title center-block text-center">
            <span className="heading-style" style={{color:"rgb(20, 92, 142)"}}>My Bookings</span>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive bg-white">
                    <table className="table table-bordered border-top table-stripped mb-0 text-nowrap">
                      <thead>
                        <tr>
                          <th className="border-bottom-0 py-3">S.No.</th>
                          <th className="border-bottom-0 py-3">Name</th>
                          <th className="border-bottom-0 py-3">User Name</th>
                          <th className="border-bottom-0 py-3">Total Members</th>
                          <th className="border-bottom-0 py-3">Price</th>
                          <th className="border-bottom-0 py-3">Concert Date</th>
                          <th className="border-bottom-0 py-3">Concert Time</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {bookingData?.map((item,index)=>{
                         
                          
                          return(
                            <tr key={index}>
                            <td>{index+1}</td>
                            <td>{item?.name}</td>
                            <td>{item?.userName}</td>
                            <td>{item?.total_members}</td>
                            <td>{item?.price}</td>
                            <td>{item?.date}</td>
                            <td>{item?.time}</td>
                          
                            </tr>
                        )})}
                      
                       
                      
                      </tbody>
                    </table>
                  </div>
                  <ul className="pagination ">
											<li className={`page-item ${page == 1 ? "page-prev disabled" : ""}`}>
												<a className="page-link" onClick={()=>setPage(page-1)} tabIndex="-1">Prev</a>
											</li>
                      {pageArr?.map((item,index)=>{   
                          return(
											<li key={index} className={`page-item ${page == index+1 ? "active" : ""}`}><a className="page-link" onClick={()=>setPage(index+1)}>{index+1}</a></li>
                      )})}
										
											<li className={`page-item ${page == pageArr.length ? "page-next disabled" : ""}`}>
												<a className="page-link" onClick={()=>setPage(page+1)}>Next</a>
											</li>
										</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mybookings;
