import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

import { Formik, FieldArray, ErrorMessage, Field, useField } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import UserService from "../services/user.service";
import moment from "moment";
const BookingDetails = () => {
	const navigate = useNavigate();
	const [concertData, setConcertData] = useState("");
	const [showmodal, setshowmodal] = useState(false);
	const [selectedType, setSelectedType] = useState("");
	const [totalMembers,setTotalMembers] = useState(1);
	const [name,setName] = useState('');

	const {
		state: { id },
	} = useLocation();
	useEffect(() => {
		console.log(id, "idididid");
		let values = {
			concert_id: id
		}
		UserService.concertDetails(values)
			.then((res) => {
				console.log(res, "resresres");
				if (res.data.status == 1) {

					setConcertData(res.data.data)
				} else {
					setConcertData("")
				}
			})
			.catch((err) => {
				const { message } = err.response.data;

			});
	}, []);
	
	const onSubmit = async (values) => {
		if (showmodal) {
			let body = {}
			if(name == ''){
				toast.info("Please enter name");
				return false
			}
			body.concert_details_id = selectedType?.id;
			body.total_members = totalMembers;
			body.userName = name;
console.log(body,"bodybodybody");
// return false
			UserService.submitConcertBooking(body)
				.then((res) => {
					if (res.data.status == 1) {
						Swal.fire({ text: "Concert Confirmed successfully", icon: "success", timer: 2000, confirmButtonColor: "#3d4cd2" });
						window.location.reload()
						window.location.href = '/mybookings';
						// navigate("/mybookings", { replace: true });
					} else {
						Swal.fire({ text: res.data.message, icon: "error", confirmButtonColor: "#3d4cd2" });
					}
				})
				.catch((err) => {
					const { message } = err.response.data;

				});
		} else {
			setshowmodal(true)
		}


	};
	const divStyle = {
		background: `url(${concertData?.banner}) center center`,
	};
	return (
		<>

			
						<div className="relative pt-10 pb-10 pattern2 bg-background-color bg-background-color-dark1 cover-image pb-9" style={divStyle}>
							<div className="header-text1 mb-0 pb-5">
								<div className="container">
									<div className="row">
										<div className="col-xl-8 col-lg-12 col-md-12 d-block mx-auto">
											<div className="text-center text-white ">
												<h1 className="mb-2"><span className="font-weight-semibold"></span></h1>
												{/* <a class="btn btn-primary mb-1 mt-1"> Book Now</a> */}
												<div className="star-ratings start-ratings-main clearfix mx-auto mb-10 mt-3 d-flex banner-ratings">
													<a className="text-white"></a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="details-absolute">
								<div className="d-sm-flex container">
									<div><a className="text-white d-inline-block mr-4 ">{moment(concertData?.date).format('DD - MMMM - YYYY')}, Time: {concertData?.time}</a></div>
									<div className="ml-auto"><a className="text-white d-inline-block mt-2 mt-sm-0"><i className="fe fe-calendar text-white mr-2 fs-14"></i>Venue: - {concertData?.address}</a></div>
								</div>
							</div>
						</div>
						<section className="sptb">
							<div className="container">
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12">
										<div className="card overflow-hidden">
											{/* <div className="ribbon ribbon-top-right text-danger"><span className="bg-danger">INR {concertData?.price}</span></div> */}

											<div className="card-body">
												<div className="item-det mb-4">
													<a className="text-dark"><h3 style={{ fontSize: 26 }}> {concertData?.name}</h3></a>
													<a className="text-black" style={{ fontSize: 20 }}><div>
													<div dangerouslySetInnerHTML={{ __html: concertData.about }} />
												</div></a>
												</div>

												<h4 className="card-title mt-6 mb-3" style={{ fontSize: 26 }}>Overview</h4>
												<a className="text-black" style={{ fontSize: 20 }}><div>
													<div dangerouslySetInnerHTML={{ __html: concertData.description }} />
												</div></a>
												<h4 className="card-title mt-6 mb-4" style={{ fontSize: 26 }}>Date & Location Details</h4>
												<div className="item-user mt-3">
													<div className="row">
														<div className="col-md-5">
															<h6 className="font-weight-normal"><span><i className="fa fa-map mr-3 mb-2"></i></span><a className="text-body" style={{ fontSize: 20 }}> Address :- {concertData?.address} </a></h6>

														</div>
														<div className="col-md-6">
															<h6 className="font-weight-normal"><span><i className="fa fa-envelope mr-3 mb-2"></i></span><a style={{ fontSize: 20 }} className="text-body"> Date & Time :- {concertData?.date} & {concertData?.time}</a></h6>
														</div>
													</div>
												</div>
												<h4 className="card-title mt-6 mb-3" style={{ fontSize: 26 }}>Terms</h4>
												<a className="text-black" style={{ fontSize: 20 }}> <div>
													<div dangerouslySetInnerHTML={{ __html: concertData.terms }} />
												</div></a>
											</div>


										</div>


									</div>


								</div>


								<center>
									{concertData?.concert_details && concertData?.concert_details.map((data) => (
										<button  style={{marginLeft:10,marginTop:10}} type="submit" className="btn btn-lg btn-success" onClick={()=>{
											setSelectedType(data)
											setshowmodal(true)}}>
											Book {data?.type} {data?.price} /- 
										</button>
									))}


								</center>
							</div>
						</section>

						<Modal show={showmodal} size="lg">
							<Modal.Header>
								<h4 >Select Number of passengers</h4>
								<button type="button" className="close" onClick={() => setshowmodal(false)}>
									<span aria-hidden="true">&times;</span>
								</button>
							</Modal.Header>
							<Modal.Body>
								<div className="row">
							<div className="col-md-6">
									<div className="form-group">
                                        <label className="form-label" >Name</label>
										<input name="name" type="text" onChange={(e)=>setName(e.target.value)} className="form-control" placeholder="Name" />
                                      </div>
									  </div><div className="col-md-6">
										<div className="form-group">
                                        <label className="form-label" >Select Number Of Member</label>
                                        <select className="form-control select2" data-placeholder="Choose Month" name="total_member"
                                          onChange={(event)=>setTotalMembers(event.target.value)}>
                                        {selectedType?.avail_members > 0 && 
                                          <option value="1">1</option>}
										  {selectedType?.avail_members > 1 && 
										  <option value="2">2</option>}
										  {selectedType?.avail_members > 2 && 
										  <option value="3">3</option>}
										  {selectedType?.avail_members > 3 && 
										  <option value="4">4</option>}
										  {selectedType?.avail_members > 4 && 
										  <option value="5">5</option>}
                                        </select>
                                       
                                      </div>
									  </div>
									  </div>
								<h4 >Payment Details</h4>
								<h5>Total Price : {selectedType?.price * totalMembers} INR</h5>
							</Modal.Body>
							<Modal.Footer>
								<button type="button" className="btn btn-primary" onClick={onSubmit}>Submit</button>
								<button type="button" className="btn btn-secondary" onClick={() => setshowmodal(false)}>Close</button>
							</Modal.Footer>
						</Modal>

		</>
	);
};

export default BookingDetails;
