import React from "react";
import { Outlet, Navigate, useLocation } from "react-router";
import { getToken } from "../utils/Auth";
import PublicNavbar from "./PublicNavbar";

const Public = () => {
  const location = useLocation();

  return getToken() ? (
    <Navigate to="/home" state={{ from: location }} replace />
  ) : (
    <>
      <PublicNavbar /> <Outlet />
    </>
  );
};

export default Public;
