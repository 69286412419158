import React from "react";
import { Link } from "react-router-dom";

import '../assets/plugins/jquery-bar-rating/dist/themes/fontawesome-stars.css';
// import '../assets/css/style.css'
const PublicNavbar = () => {

	return (

		<div className="header-main header-style03">



			<div className="sticky">
				<div className="horizontal-header clearfix ">
					<div className="container">
						<a id="horizontal-navtoggle" className="animated-arrow"><span></span></a>
						<span className="smllogo"><a href="index.html"><img src="https://qcbiztech.in/fdworld/public/web/images/fdlogo.png" alt="" /></a></span>
						<a href="add-post.html" className="callusbtn"><i className="fe fe-plus-circle" aria-hidden="true"></i></a>
					</div>
				</div>
			</div>


			<div className="horizontal-main  bg-dark-transparent clearfix py-lg-3">
				<div className="horizontal-mainwrapper container clearfix">
					<div className="desktoplogo">
						<a href="index.html"><img src="https://qcbiztech.in/fdworld/public/web/images/fdlogo.png" alt="" /></a>
					</div>
					<div className="desktoplogo-1">
						<a href="index.html"><img src="https://qcbiztech.in/fdworld/public/web/images/fdlogo.png" alt="" /></a>
					</div>

					<nav className="horizontalMenu clearfix d-md-flex">
						<ul className="horizontalMenu-list">



							<li> <Link to={"/register"} style={{ textDecoration: "none" }}> Register</Link></li>

							<li className="">
								<span><Link to={"/login"}><a className="btn btn-secondary" ><i className="fe fe-plus"></i> Login</a></Link></span>
							</li>
						</ul>
					</nav>

				</div>
				<div className="body-progress-container">
					<div className="progress-bar progress-bar-striped progress-bar-animated bg-success" id="myBar"></div>
				</div>
			</div>
		</div>

	);
};

export default PublicNavbar;
