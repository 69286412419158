import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import UserService from "../services/user.service";
import { PASSWORDS_REGEX } from "../utils/Constants";
const ChangePassword = () => {
  const navigate = useNavigate();
  const initialValues = { oldpassword: "", password: "", cpassword: "" };

  const validationSchema = Yup.object().shape({
    oldpassword: Yup.string().required("Old password is required"),
    password: Yup.string().required("Password is required").matches(PASSWORDS_REGEX, "Must be minimum 8  characters long and alphanumeric with at least 1 uppercase letter, 1 number and 1 special character"),
    cpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const onSubmit = async (values) => {

    UserService.changePassword(values)
      .then((res) => {
        if (res.data.status == 1) {
          Swal.fire({ text: res.data.message, icon: "success", timer: 2000, confirmButtonColor: "#3d4cd2" });
          navigate("/home", { replace: true });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        const { message } = err.response.data;
        toast.error(message);
      });
  };
  return (
    <>
      <section className="sptb">
        <div className="container">
          <div className="section-title center-block text-center">
            <span className="heading-style" style={{ color: "rgb(20, 92, 142)" }}>Change Password</span>

          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="card-body"
                tabIndex="500"
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Change Password</h3>
                      </div>
                      <div className="card-body">
                        <div className="tab-content card-body border mb-0 b-0">
                          <div className="panel panel-secondary">

                            <div className="panel-body tabs-menu-body pl-0 pr-0 border-0">
                              <div className="tab-content">
                                <div className="tab-pane active " id="tab5">

                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label className="form-label" >Old Password</label>
                                        <input
                                          placeholder="Old Password"
                                          className="form-control"
                                          type="password"
                                          name="oldpassword"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.oldpassword}
                                        />
                                        <p className="text-danger">
                                          {errors.oldpassword && touched.oldpassword && errors.oldpassword}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label className="form-label" >New Password</label>
                                        <input
                                          placeholder="New Password"
                                          className="form-control"
                                          type="password"
                                          name="password"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.password}
                                        />
                                        <p className="text-danger">
                                          {errors.password && touched.password && errors.password}
                                        </p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label className="form-label" >Confirm Password</label>
                                        <input
                                          placeholder="Password"
                                          className="form-control"
                                          type="password"
                                          name="cpassword"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.cpassword}
                                        />
                                        <p className="text-danger">
                                          {errors.cpassword && touched.cpassword && errors.cpassword}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div className="form-group row clearfix">
                            <div className="col-lg-12">
                              <button disabled={isSubmitting} className="btn btn-secondary  mb-0 mr-2">Submit</button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div> </form>)}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
