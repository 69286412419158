import React from "react";
import { Outlet, Navigate, useLocation } from "react-router";
import { getToken } from "../utils/Auth";
import PrivateNavbar from "./PrivateNavbar";

const Private = () => {
  const location = useLocation();
  console.log(location,"324432")
  return getToken() ? (
    <>
    {location?.pathname != '/tickets' &&
      <PrivateNavbar />}
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default Private;
