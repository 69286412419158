import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../services/user.service";
const PrivateNavbar = () => {
  const navigate = useNavigate();
  const [userdata, setUserData] = useState('');
  useEffect(() => {
    UserService.getUserDetail()
      .then((res) => {
        setUserData(res.data?.data);
      })
      .catch((err) => {
        const { message } = err.response?.data;
       
      });
  }, []);

  const handleLogout = (e) => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="header-main header-style03">
      <div className="sticky">
        <div className="horizontal-header clearfix ">
          <div className="container">
            <a id="horizontal-navtoggle" className="animated-arrow">
              <span></span>
            </a>
            <span className="smllogo">
              <a href="index.html">
                <img
                  src="https://fdworld.in/user/public/web/images/fdlogo.png"
                  alt=""
                />
              </a>
            </span>
            <a href="add-post.html" className="callusbtn">
              <i className="fe fe-plus-circle" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="horizontal-main  bg-dark-transparent clearfix py-lg-3">
        <div className="horizontal-mainwrapper container clearfix">
          <div className="desktoplogo">
          <Link to={"/home"}>
            <a href="">
              <img
                src="https://fdworld.in/user/public/web/images/fdlogo.png"
                alt=""
              />
            </a>
            </Link>
          </div>
          <div className="desktoplogo-1">
            <a href="index.html">
              <img
                src="https://fdworld.in/user/public/web/images/fdlogo.png"
                alt=""
              />
            </a>
          </div>

          <nav className="horizontalMenu clearfix d-md-flex">
            <ul className="horizontalMenu-list">
            <li>
            <a style={{fontSize:14,color:"#145c8e"}}>Available Balance : Rs {userdata?.avail_bal}</a>
              </li>
              <li>
            <a style={{fontSize:14,color:"#145c8e"}}>Credit Limit : Rs {userdata?.credit_limit}</a>
              </li>
              <li>
            <a style={{fontSize:14,color:"#145c8e"}}>Due : - Rs {userdata?.due}</a>
              </li>
            <li> <span>
                  <a className="btn btn-secondary">
                     Home
                  </a>
                </span>
								<ul class="sub-menu">
                <li>
                <Link to={"/home"}>
                  <a href="#">Home</a>
                </Link>
              </li>
              <li>
                <Link to={"/mybookings"}>
                  <a href="#">My Booking</a>
                </Link>
              </li>
              <li>
                <Link to={"/mypayment"}>
                  <a href="#">My Payment</a>
                </Link>
              </li>
              <li>
                <Link to={"/myledger"}>
                  <a href="#">My Ledger</a>
                </Link>
              </li>
              <li>
                <Link to={"/ChangePassword"}>
                  <a href="#">Change Password</a>
                </Link>
              </li>
              <li><a onClick={handleLogout}>Logout </a></li>
								</ul>
							</li>
             

              {/* <li>
                <Link to={"/aboutus"}>
                  <a href="about.html">About Us </a>
                </Link>
              </li> */}

             
            </ul>
          </nav>
        </div>
        <div className="body-progress-container">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-success"
            id="myBar"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default PrivateNavbar;
