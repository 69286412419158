import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section>
      <footer className="bg-dark-purple text-white">
        <div className="footer-main border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-12">
                <h6>Get In Touch</h6>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-home mr-3 text-secondary"></i> Guwahati, Assam
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-envelope mr-3 text-secondary fs-12"></i>{" "}
                      tickets@fdworld.in
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-phone mr-3 text-secondary"></i> 7429531222
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fa fa-print mr-3 text-secondary"></i> + 01
                      234 567 89
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12">
                <h6>Popular Tour Places</h6>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-angle-double-right mr-2 text-secondary"></i>{" "}
                      GAU DEL{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-angle-double-right mr-2 text-secondary"></i>{" "}
                      DEL GAU
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-angle-double-right mr-2 text-secondary"></i>{" "}
                      GAU CCU
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-angle-double-right mr-2 text-secondary"></i>{" "}
                      CCU GAU
                    </a>
                  </li>
                </ul>
                
              </div>
              <div className="col-xl-2 col-lg-6 col-md-12">
                <h6 className="">Popular Tour Places</h6>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-angle-double-right mr-2 text-secondary"></i>{" "}
                      GAU MAA
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-angle-double-right mr-2 text-secondary"></i>{" "}
                      MAA GAU
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-angle-double-right mr-2 text-secondary"></i>{" "}
                      GAU BLR
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-angle-double-right mr-2 text-secondary"></i>{" "}
                      BLR GAU
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-dark-purple text-white p-0 border-bottom">
          <div className="container">
            <div className="p-2 text-center footer-links">
            <Link to={"/home"}>
              <a href="#" className="btn btn-link">
                Home
              </a>
              </Link>
              <Link to={"/mybookings"}>
              <a href="#" className="btn btn-link">
                My Bookings
              </a>
              </Link>
              <Link to={"/myledger"}>
              <a href="#" className="btn btn-link">
                My Ledger
              </a>
              </Link>
             
              <Link to={"/mypayment"}>
              <a href="#" className="btn btn-link">
                My Payment
              </a>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-dark-purple text-white-50 p-3">
          <div className="container">
            <div className="row d-flex">
              <div className="col-lg-12 col-sm-12  mt-2 mb-2 text-center ">
                Copyright © 2023 All rights reserved.
              </div>
             
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
