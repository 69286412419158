import React from "react";
import { Link } from "react-router-dom";

const Aboutus = () => {
  return (
    <>
      <section
        className="sptb cover-image bg-background-color py-9"
        data-image-src="https://qcbiztech.in/fdworld/public/web/images/banners/banner13.jpg"
      >
        <div className="container">
          <div className="section-title center-block text-center content-text m-0 p-0 row py-4">
            <div className="col-lg-10 d-block mx-auto">
              <h2 className="text-white fs-50 font-weight-semibold">
                Great Adventure Tour in{" "}
                <span className="font-weight-bold text-white">Austerlia</span>
              </h2>
              <p className="fs-16 mt-4 leading-normal text-white">
                We denounce with righteous indignation and dislike men who are
                so beguiled and demoralized by the charms of pleasure of the
                moment Mauris ut cursus nunc. Morbi eleifend, ligula at
                consectetur vehicula
              </p>
              <a
                className="mt-6 d-block video-btn mx-auto"
                href="#"
                data-toggle="modal"
                data-target="#homeVideo"
              >
                <i className="fa fa-play text-white-80"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="sptb position-relative">
        <div className="container">
          <div className="section-title center-block text-center pb-5">
            <span className="heading-style text-secondary">Our</span>
            <h1 className="position-relative">Tranportation Facilities</h1>
            <p>
              Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
              vehicula
            </p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div id="myCarousel" className="owl-carousel owl-carousel-icons">
                <div className="item">
                  <div className="card overflow-hidden mb-0">
                    <div className="item-card2-img">
                      <a href="cars.html" className="absolute-link"></a>
                      <img
                        src="https://qcbiztech.in/fdworld/public/web/images/products/products/v2.jpg"
                        alt="img"
                        className="cover-image"
                      />
                      <div className="item-card2-icons">
                        <a href="#" className="item-card2-icons-r">
                          <i className="fa fa fa-heart-o"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="item-card2">
                        <div className="">
                          <div className="item-card2-text">
                            <a href="cars.html" className="text-dark">
                              <h4 className="mb-0 fs-20 font-weight-bold">
                                Cars
                              </h4>
                            </a>
                            <p className="leading-normal-2 mt-2">
                              Nemo enim ipsam voluptatem quia voluptas sit
                              aspernatur aut odit aut fugit.
                            </p>
                          </div>
                          <div className="pt-2 row">
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                GPS Tracking
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Food Facilities
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Air Conditioned
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                TV and Audios
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Free Wifi
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Push back Seats
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                High Rated Driver
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Extra Facilities
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex">
                      <div className="item-card2-footer">
                        <div className="item-card2-footer-u">
                          <div className="d-flex">
                            <div className="star-ratings start-ratings-main clearfix d-inline-flex">
                              <div className="stars stars-example-fontawesome stars-example-fontawesome-sm mr-2">
                                <select
                                  className="example-fontawesome"
                                  name="rating"
                                  autoComplete="off"
                                >
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>{" "}
                              25 Reviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-auto">
                        <a className="" href="#">
                          <i className="fa fa-comment-o"></i> 45
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card overflow-hidden mb-0">
                    <div className="item-card2-img">
                      <a href="flights.html" className="absolute-link"></a>
                      <img
                        src="https://qcbiztech.in/fdworld/public/web/images/categories/flights/1.jpg"
                        alt="img"
                        className="cover-image"
                      />
                      <div className="item-card2-icons">
                        <a href="#" className="item-card2-icons-r">
                          <i className="fa fa fa-heart-o"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="item-card2">
                        <div className="">
                          <div className="item-card2-text">
                            <a href="flights.html" className="text-dark">
                              <h4 className="mb-0 fs-20 font-weight-bold">
                                Flight
                              </h4>
                            </a>
                            <p className="leading-normal-2 mt-2">
                              Nemo enim ipsam voluptatem quia voluptas sit
                              aspernatur aut odit aut fugit.
                            </p>
                          </div>
                          <div className="pt-2 row">
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                GPS Tracking
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Food Facilities
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Air Conditioned
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                TV and Audios
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Free Wifi
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Push back Seats
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                High Rated Driver
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Extra Facilities
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex">
                      <div className="item-card2-footer">
                        <div className="item-card2-footer-u">
                          <div className="d-flex">
                            <div className="star-ratings start-ratings-main clearfix d-inline-flex">
                              <div className="stars stars-example-fontawesome stars-example-fontawesome-sm mr-2">
                                <select
                                  className="example-fontawesome"
                                  name="rating"
                                  autoComplete="off"
                                >
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>{" "}
                              75 Reviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-auto">
                        <a className="" href="#">
                          <i className="fa fa-comment-o"></i> 75
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card overflow-hidden mb-0">
                    <div className="item-card2-img">
                      <a href="cruises.html" className="absolute-link"></a>
                      <img
                        src="https://qcbiztech.in/fdworld/public/web/images/categories/cruises/1.jpg"
                        alt="img"
                        className="cover-image"
                      />
                      <div className="item-card2-icons">
                        <a href="#" className="item-card2-icons-r">
                          <i className="fa fa fa-heart-o"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="item-card2">
                        <div className="">
                          <div className="item-card2-text">
                            <a href="cruises.html" className="text-dark">
                              <h4 className="mb-0 fs-20 font-weight-bold">
                                Cruise
                              </h4>
                            </a>
                            <p className="leading-normal-2 mt-2">
                              Nemo enim ipsam voluptatem quia voluptas sit
                              aspernatur aut odit aut fugit.
                            </p>
                          </div>
                          <div className="pt-2 row">
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                GPS Tracking
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Food Facilities
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Air Conditioned
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                TV and Audios
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Free Wifi
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Push back Seats
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                High Rated Driver
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Extra Facilities
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex">
                      <div className="item-card2-footer">
                        <div className="item-card2-footer-u">
                          <div className="d-flex">
                            <div className="star-ratings start-ratings-main clearfix d-inline-flex">
                              <div className="stars stars-example-fontawesome stars-example-fontawesome-sm mr-2">
                                <select
                                  className="example-fontawesome"
                                  name="rating"
                                  autoComplete="off"
                                >
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>{" "}
                              68 Reviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-auto">
                        <a className="" href="#">
                          <i className="fa fa-comment-o"></i> 12
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card overflow-hidden mb-0">
                    <div className="item-card2-img">
                      <a href="cruises.html" className="absolute-link"></a>
                      <img
                        src="https://qcbiztech.in/fdworld/public/web/images/categories/cruises/6.jpg"
                        alt="img"
                        className="cover-image"
                      />
                      <div className="item-card2-icons">
                        <a href="#" className="item-card2-icons-r">
                          <i className="fa fa fa-heart-o"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="item-card2">
                        <div className="">
                          <div className="item-card2-text">
                            <a href="cruises.html" className="text-dark">
                              <h4 className="mb-0 fs-20 font-weight-bold">
                                Mini Cruise
                              </h4>
                            </a>
                            <p className="leading-normal-2 mt-2">
                              Nemo enim ipsam voluptatem quia voluptas sit
                              aspernatur aut odit aut fugit.
                            </p>
                          </div>
                          <div className="pt-2 row">
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                GPS Tracking
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Food Facilities
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Air Conditioned
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                TV and Audios
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Free Wifi
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Push back Seats
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                High Rated Driver
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Extra Facilities
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex">
                      <div className="item-card2-footer">
                        <div className="item-card2-footer-u">
                          <div className="d-flex">
                            <div className="star-ratings start-ratings-main clearfix d-inline-flex">
                              <div className="stars stars-example-fontawesome stars-example-fontawesome-sm mr-2">
                                <select
                                  className="example-fontawesome"
                                  name="rating"
                                  autoComplete="off"
                                >
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>{" "}
                              75 Reviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-auto">
                        <a className="" href="#">
                          <i className="fa fa-comment-o"></i> 65
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="card overflow-hidden mb-0">
                    <div className="item-card2-img">
                      <a href="buses.html" className="absolute-link"></a>
                      <img
                        src="https://qcbiztech.in/fdworld/public/web/images/categories/buses/1.jpg"
                        alt="img"
                        className="cover-image"
                      />
                      <div className="item-card2-icons">
                        <a href="#" className="item-card2-icons-r">
                          <i className="fa fa fa-heart-o"></i>
                        </a>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="item-card2">
                        <div className="">
                          <div className="item-card2-text">
                            <a href="buses.html" className="text-dark">
                              <h4 className="mb-0 fs-20 font-weight-bold">
                                Mini Buses
                              </h4>
                            </a>
                            <p className="leading-normal-2 mt-2">
                              Nemo enim ipsam voluptatem quia voluptas sit
                              aspernatur aut odit aut fugit.
                            </p>
                          </div>
                          <div className="pt-2 row">
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                GPS Tracking
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Food Facilities
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Air Conditioned
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                TV and Audios
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Free Wifi
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Push back Seats
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                High Rated Driver
                              </div>
                            </a>
                            <a
                              className="mb-1 col-sm-6 col-md-12 col-lg-6"
                              href="#"
                            >
                              <div className="pb-0 pt-0 mb-1 mt-1">
                                <i className="fe fe-check mr-2 d-inline-block text-primary"></i>
                                Extra Facilities
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex">
                      <div className="item-card2-footer">
                        <div className="item-card2-footer-u">
                          <div className="d-flex">
                            <div className="star-ratings start-ratings-main clearfix d-inline-flex">
                              <div className="stars stars-example-fontawesome stars-example-fontawesome-sm mr-2">
                                <select
                                  className="example-fontawesome"
                                  name="rating"
                                  autoComplete="off"
                                >
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>{" "}
                              85 Reviews
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-auto">
                        <a className="" href="#">
                          <i className="fa fa-comment-o"></i> 96
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutus;
