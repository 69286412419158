import axios from "axios";
import { BASE_API } from "../utils/Constants";


const AuthService = {
  register(value) {
    return axios.post(`${BASE_API}/register`, value);
  },
  login(credentials) {
    return axios.post(`${BASE_API}/login`, credentials);
  },
  sectors() {
    return axios.get(`${BASE_API}/getConcertList`);
  },
  logout() {
    return localStorage.removeItem("token");
  },
  forgotPassword(value) {
    return axios.post(`${BASE_API}/forgotPassword`, value);
  },
  resetPassword(value) {
    return axios.post(`${BASE_API}/resetpasswordInstitute`, value);
  },
};

export default AuthService;
