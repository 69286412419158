import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import UserService from "../services/user.service";
const PaymentRequest = () => {
  const navigate = useNavigate();
  const initialValues = { depositedbankaccount: "", paymentmode: "", transactionid: "", deposited_amount: "", paymentdate: "", remarks: "" };
  const [bankdata, setBankData] = useState('');
  useEffect(() => {
    UserService.getBankDetail()
      .then((res) => {
        setBankData(res.data?.data);
      })
      .catch((err) => {
        const { message } = err.response?.data;
       
      });
  }, []);
  const validationSchema = Yup.object().shape({
    depositedbankaccount: Yup.mixed().required("Our Bank Accounts is required"),
    paymentmode: Yup.mixed().required("Payment Mode is required"),
    transactionid: Yup.mixed().required("Ref. Number is required"),
    deposited_amount: Yup.mixed().required("Deposit Amount is required"),
    paymentdate: Yup.mixed().required("Deposit Date is required"),
    remarks: Yup.mixed().required("Remarks is required")
  });
  const onSubmit = async (values) => {

    UserService.paymentRequest(values)
      .then((res) => {
        if (res.data.status == 1) {
          Swal.fire({ text: res.data.message, icon: "success", timer: 2000, confirmButtonColor: "#3d4cd2" });
          navigate("/mypayment", { replace: true });
        } else {

        }
      })
      .catch((err) => {
        const { message } = err.response.data;

      });
  };
  return (
    <>
      <section className="sptb">
        <div className="container">
          <div className="section-title center-block text-center">
            <span className="heading-style" style={{ color: "rgb(20, 92, 142)" }}>Make Request</span>

          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="card-body"
                tabIndex="500"
              >
                <div className="row">
                  <div className="col-md-8">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Payments</h3>
                      </div>
                      <div className="card-body">
                        <div className="tab-content card-body border mb-0 b-0">
                          <div className="panel panel-secondary">
                            <div className=" tab-menu-heading border-0 pl-0 pr-0 pt-0">
                              <div className="tabs-menu1 ">

                                <ul className="nav panel-tabs">
                                  <li><a href="#tab5" className="active" data-toggle="tab">Payment Request</a></li>
                                  <li><a href="#tab6" data-toggle="tab">Scanner</a></li>

                                </ul>
                              </div>
                            </div>
                            <div className="panel-body tabs-menu-body pl-0 pr-0 border-0">
                              <div className="tab-content">
                                <div className="tab-pane active " id="tab5">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label className="form-label" >Our Bank Accounts</label>
                                        <select className="form-control select2" data-placeholder="Choose Month" name="depositedbankaccount" onChange={handleChange}
                                          onBlur={handleBlur}>
                                          <option value="0">--Select--</option>
                                          <option value="AXIS-922020041724289">AXIS-922020041724289</option>
                                          
                                        </select>
                                        <p className="text-danger">
                                          {errors.depositedbankaccount && touched.depositedbankaccount && errors.depositedbankaccount}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label className="form-label" >Payment Mode</label>
                                        <select className="form-control select2" data-placeholder="Choose Month" name="paymentmode" onChange={handleChange}
                                          onBlur={handleBlur}>
                                          <option value="0">--Select--</option>
                                          <option value="NEFT">NEFT</option>
                                          <option value="IMPS">IMPS</option>
                                          <option value="Cash">Cash</option>
                                        </select>
                                        <p className="text-danger">
                                          {errors.paymentmode && touched.paymentmode && errors.paymentmode}
                                        </p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label className="form-label" >Ref. Number</label>
                                        <input name="transactionid" onChange={handleChange}
                                          onBlur={handleBlur} type="text" className="form-control" placeholder="Ref. Number" />
                                        <p className="text-danger">
                                          {errors.transactionid && touched.transactionid && errors.transactionid}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label className="form-label">Deposit Amount</label>
                                        <input name="deposited_amount" onChange={handleChange}
                                          onBlur={handleBlur} type="number" className="form-control" placeholder="Deposit Amount" />
                                        <p className="text-danger">
                                          {errors.deposited_amount && touched.deposited_amount && errors.deposited_amount}
                                        </p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label className="form-label">Deposit Date</label>
                                        <input type="date" name="paymentdate" onChange={handleChange}
                                          onBlur={handleBlur} className="form-control" placeholder="Deposit Date" />
                                        <p className="text-danger">
                                          {errors.paymentdate && touched.paymentdate && errors.paymentdate}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <label className="form-label">Remarks</label>
                                        <textarea class="form-control" rows="6" placeholder="Remarks" name="remarks" onChange={handleChange}
                                          onBlur={handleBlur} required=""></textarea>
                                        <p className="text-danger">
                                          {errors.remarks && touched.remarks && errors.remarks}
                                        </p>
                                      </div>
                                    </div>

                                  </div>
                                  <div className="form-group row clearfix">
                                    <div className="col-lg-12">
                                      <button disabled={isSubmitting} className="btn btn-secondary  mb-0 mr-2">Submit</button>
                                    </div>

                                  </div>
                                </div>
                                <div className="tab-pane " id="tab6">
                                  <div class="col-lg-6">
                                  
                                    <img src={bankdata?.logo} alt="" />
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Bank Details</h3>
                      </div>
                      <div className="card-body">
                      <div dangerouslySetInnerHTML={{ __html: bankdata.content_en }} />
                      </div>
                    </div>
                  </div>
                </div> </form>)}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default PaymentRequest;
