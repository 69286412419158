import React, { useLayoutEffect } from 'react';
import { Route, Routes, Navigate,useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "./components/footer";

import Login from "./pages/auth/login";

import Register from "./pages/register/register";
import ResetPassword from "./pages/reset-password/reset";
import Private from "./components/Private";
import Public from "./components/Public";
import Home from "./components/home";
import Mybookings from "./components/mybookings";
import Tickets from "./components/tickets";
import Myledger from "./components/myledger";
import Mypayment from "./components/mypayment";
import Aboutus from "./components/aboutus";
import BookingDetails from "./components/bookingdetails";
import PaymentRequest from './components/paymentRequest';
import ChangePassword from './components/changePassword';
function App() {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <ToastContainer
        theme="dark"
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />

      <Routes>
        <Route element={<Private />}>
          <Route path="/home" element={<Home />} />
          <Route path="/mybookings" element={<Mybookings />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/myledger" element={<Myledger />} />
          <Route path="/mypayment" element={<Mypayment />} />
          <Route path="/PaymentRequest" element={<PaymentRequest />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/BookingDetails" element={<BookingDetails />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
        </Route>

        <Route element={<Public />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password/:token_id" element={<ResetPassword />} />
          <Route path="/*" element={<Navigate to="/login" replace />} />
        </Route>
      </Routes>
      {location?.pathname != '/tickets' &&
      <Footer></Footer>}
    </>
  );
}

export default App;
