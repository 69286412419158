import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { toast } from "react-toastify";
import Autocomplete from "react-autocomplete";
import moment from "moment";
const Home = () => {
  const [concerts, setConcerts] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0); 
  const [notice, setNotice] = useState('');
  const [searchResult, setsearchResult] = useState([]);

  const handleSelectChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
  };
  useEffect(() => {
    AuthService.sectors()
      .then((res) => {
        console.log(res, "resresresres");
        setConcerts(res.data?.data);
        // setNotice(res.data?.data?.setting_data)
      })
      .catch((err) => {
        const { message } = err.response?.data;
        if (message) {
          toast.info(message);
        } else {
          toast.info('Something went Wrong!!');
        }
      });
  }, []);
  const onSubmitHandler = (sector_id) => {

    let values = {
      sector_id: sector_id
    }
    UserService.searchFlights(values)
      .then((res) => {

        if (res.data.status == 1) {
          setsearchResult(res.data.data)
          document.getElementById('flight_list').scrollIntoView();
        } else {
          setsearchResult([])
          toast.info('No flights found.');
        }


      })
      .catch((err) => {
        const { message } = err.response.data;

      });


  }
  const divStyle = {
		background: `url(https://fdworld.in/user/uploads/11579.png) center center`,
	};
  return (
    <>
      <section>
        <div
          className="relative pt-10 pb-10 pattern2 bg-background-color bg-background-color-dark1 cover-image pb-9"
          data-image-src="https://qcbiztech.in/fdworld/public/web/images/banners/banner1.jpg"
          style={divStyle}>
          {/* <div className="text-white row">
            <div className="col-xl-2 col-lg-2 col-md-2">

            </div>
            <div className="col-xl-8 col-lg-8 col-md-8">
              <div className="support-service br-5" style={{ padding: 0.5, paddingBlock: 5, paddingInline: 10, backgroundColor: "#ff6600" }}>

                <h6 className="font-weight-semibold2 mt-1">{notice?.notice?.value}</h6>

              </div>
              <div className="support-service br-5" style={{ padding: 0.5, marginTop: 10, paddingBlock: 5, paddingInline: 10, backgroundColor: "#ff6600" }}>

                <h6 className="font-weight-semibold2 mt-1">{notice?.notice2?.value}</h6>

              </div>
            </div>


          </div> */}
          <div className="header-text mb-0">
            <div className="container">
              <div className="text-center text-white mb-4">
                <h1 className="mb-3">FDWorld - Everything is Fixed Here !!</h1>
                <p>
                Concert Ticket Finder: Discover and Book Your Next Live Event!
                </p>
              </div>
              <div className="row">
                <div className="col-xl-10 col-lg-12 col-md-12 d-block mx-auto">
                  <div className="row mt-4">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                      <select
            className="form-control select2"
            data-placeholder="Choose Month"
            name="depositedbankaccount"
            value={selectedValue}
            onChange={handleSelectChange}
          >
                         <option value="0">--Select--</option>
            {concerts.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.name} (Date : {moment(item?.date).format('DD-MM-YYYY')})
              </option>
            ))}


                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <Link to={"/BookingDetails"} state={{ id: selectedValue }}>
                        <button className="btn btn-secondary  mb-0 mr-2">Submit</button>
                      </Link>

                    </div>
                    {/* {sectors.map((item, index) => (
                <div className="col">
                  <a className="p-3 tour-service" onClick={()=>onSubmitHandler(item?.id)}>
                    <img src="https://qcbiztech.in/fdworld/public/web/images/categories/plane.png" alt="img" className="w-6 h-6 mb-0 text-center mx-auto" />
                    <h6>{item.origin} - {item.destination}</h6>
                  </a>
              </div>))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




    </>
  );
};

export default Home;
