import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import UserService from "../services/user.service";
import '../assets/css/ticketcss.css';
import moment from "moment";
const Tickets = () => {
  const [bookingData, setbookingData] = useState("");
  const [passData, setpassData] = useState([]);
  const [infantDetails, setinfantDetails] = useState([]);

  const {
    state: { id },
  } = useLocation();
  useEffect(() => {
    let value = {
      booking_id: id
    }
    UserService.ticketDetails(value)
      .then((res) => {
        if (res.data.status == 1) {
          console.log(res.data)
          setbookingData(res?.data?.data)
          setpassData(JSON.parse(res?.data?.data?.passengers_details));
          setinfantDetails(JSON.parse(res?.data?.data?.infant_details))
          // let PagArr = [];
          // for (let index = 0; index < res.data.data.last_page; index++) {
          //   PagArr.push(index);

          // }
          // setPageArr(PagArr);
          // setbookingData(res.data.data.data)
        } else {
          // setbookingData("")
        }
      })
      .catch((err) => {
        console.log(err)
        const { message } = err.response.data;

      });
  }, []);
 const print = () => {
    window.print();
}
  return (
    <>
      <div style={{ padding: 25, margin: 10, }}>
        <div style={{ backgroundColor: "white", padding: 25 }}>
          <div>
            <table role="presentation" border="0" cellpadding="0" cellspacing="0" className="body tickettable">
              <tbody style={{}}>
                <tr>
                  <td className="container">
                    <div className="content">
                      <table role="presentation" className="main tickettable">
                        <tbody>
                          <tr>
                            <td className="wrapper">
                              <table role="presentation" className="tickettable" border="0" cellpadding="0" cellspacing="0">
                                <tbody>
                                  <tr>
                                    <td>
                                      <table className="tickettable" role="presentation" border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                          <tr>
                                            <td className="text-left font-md"
                                            >
                                              <div className="btn-group" id="btn_hide_print"><a
                                                ><button className="btn btn-secondary"
                                                onClick={print}> Print </button></a>
                                                <Link to={"/mybookings"}><a style={{marginLeft:10}} className="btn btn-secondary">Back </a></Link>
                                              </div>
                                            </td>
                                            <td className="text-right font-md text-dark">
                                              <p className="
                                                            text-uppercase text-primary
                                                            font-weight-bold
                                                            " > {bookingData?.first_name} {bookingData?.last_name} </p>
                                                            <p className="
                                                            text-primary
                                                            font-weight-bold
                                                            " style={{marginTop:-15}}> {bookingData?.email} </p>
                                              Booking ID : {bookingData?.id} <br /> Booked on : {moment(bookingData?.created).format('D-MMM-yy H:mm:ss')}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0"
                                        className="py-1 tickettable">
                                        <tbody>
                                          <tr>
                                            <td colspan="4"
                                            >
                                              &nbsp; </td>
                                          </tr>
                                          <tr>
                                            {/* <td valign="middle" width="180"><img
                                              src={bookingData?.airline_logo}
                                              width="150" height="43" alt="" className="img-responsive" />
                                            </td> */}
                                            <td valign="middle">
                                              <p className="mb-0" style={{fontSize:38,fontWeight:"bold"}}> {bookingData?.airlinename} </p>

                                            </td>
                                            <td valign="middle">
                                              <p className="mb-0">Agency</p>
                                              <p className="text-primary"><strong> {bookingData?.mobile}</strong></p>
                                             
                                            </td>
                                            <td valign="middle" width="150"
                                              className="border border-dark p-05 text-center">
                                              <p className="text-primary text-uppercase mb-0"> Airline PNR
                                              </p>
                                              <h3 className="mb-0"><strong>{bookingData?.pnr}</strong></h3>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="tickettable" role="presentation" border="0" cellpadding="0" cellspacing="0"
                                        style={{ marginTop: 10 }}>
                                        <tbody>
                                          <tr>
                                            <td className="bg-primary text-white py-05 px-2">
                                              <table className="tickettable" role="presentation" border="0" cellpadding="0"
                                                cellspacing="0">
                                                <tbody>
                                                  <tr style={{ color: "white" }}>
                                                    <td>Onward Flight Details</td>
                                                    <td className="text-right"> *Please verify flight times
                                                      with the airlines prior to departure </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="border border-primary">
                                              <table className="tickettable" role="presentation" border="0" cellpadding="0"
                                                cellspacing="0">
                                                <tbody>
                                                  <tr>
                                                    <td className="bg-gray text-dark py-05 px-2"> Flight
                                                    </td>
                                                    <td className="bg-gray text-dark py-05 px-2"> Departing
                                                    </td>
                                                    <td colspan="2"
                                                      className="bg-gray text-dark py-05 px-2"> Arriving
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="py-05 px-2 text-dark">{bookingData?.airlinename} {bookingData?.airlinecode} {bookingData?.flight_number}</td>
                                                    <td className="py-05 px-2 text-dark"> {bookingData?.origin}<br />
                                                      {moment(bookingData?.date).format('D-MMM-yy')},{bookingData?.dep_time} </td>
                                                    <td className="py-05 px-2 text-dark"> {bookingData?.destination}<br /> {bookingData?.next_day == 1 ? moment(bookingData?.date).format('D-MMM-yy'):moment(bookingData?.date).format('D-MMM-yy')},
                                                      {bookingData?.arr_time} </td>
                                                    <td className="py-05 px-2 text-dark">
                                                      Direct<br /><strong>Non-Refundable</strong></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="tickettable" role="presentation" border="0" cellpadding="1" cellspacing="0">
                                        <tbody>
                                          <tr>
                                            <td>&nbsp;</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="tickettable" role="presentation" border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                          <tr>
                                            <td className="bg-dark text-white py-05 px-2"> Passenger(s) Details
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="border border-dark">
                                              <table className="tickettable" role="presentation" border="0" cellpadding="0"
                                                cellspacing="0">
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <table className="tickettable" role="presentation" border="0"
                                                        cellpadding="0" cellspacing="0">
                                                        <tbody>
                                                          <tr>
                                                            <td width="80"
                                                              className="bg-gray py-05 px-2 text-dark">
                                                              Sl No. </td>
                                                            <td
                                                              className="bg-gray py-05 px-2 text-dark">
                                                              Passenger(s) Name </td>
                                                            <td
                                                              className="bg-gray py-05 px-2 text-dark">
                                                              WheelChair </td>
                                                            <td
                                                              className="bg-gray py-05 px-2 text-dark">
                                                              Type </td>
                                                            <td
                                                              className="bg-gray py-05 px-2 text-dark">
                                                              DOB </td>
                                                          </tr>
                                                          {passData?.map((item, index) => {
                                                            return (
                                                              <tr>
                                                                <td className="py-05 px-2 text-dark">{index + 1}</td>
                                                                <td className="
                                                                           py-05
                                                                           px-2
                                                                           text-dark text-uppercase
                                                                           ">{item?.title} {item?.first_name} {item?.last_name} </td>
                                                                <td className="py-05 px-2 text-dark">
                                                                  <span> {item?.wheelchair}</span>
                                                                </td>
                                                                <td className="py-05 px-2 text-dark">
                                                                  <span> Adult</span>
                                                                </td>
                                                              </tr>)
                                                          })}
                                                          {infantDetails?.map((item, index) => {
                                                            return (
                                                              <tr>
                                                                <td className="py-05 px-2 text-dark">{passData.length + index + 1}</td>
                                                                <td className="
                                                                           py-05
                                                                           px-2
                                                                           text-dark text-uppercase
                                                                           ">{item?.title} {item?.first_name} {item?.last_name} </td>
                                                                <td className="py-05 px-2 text-dark">
                                                                  <span> {item?.wheelchair}</span>
                                                                </td>
                                                                <td className="py-05 px-2 text-dark">
                                                                  <span> Adult</span>
                                                                </td>
                                                                <td className="py-05 px-2 text-dark">{item?.dob}</td>
                                                              </tr>)
                                                          })}

                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="tickettable" role="presentation" border="0" cellpadding="1" cellspacing="0">
                                        <tbody>
                                          <tr>
                                            <td>&nbsp;</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="tickettable" role="presentation" border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <table className="tickettable" role="presentation" border="0" cellpadding="0"
                                                cellspacing="0">
                                                <tbody>
                                                  <tr>
                                                    <td className="border border-dark">
                                                      <table className="tickettable" role="presentation" border="0"
                                                        cellpadding="0" cellspacing="0">
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              <table className="tickettable" role="presentation"
                                                                border="0" cellpadding="0"
                                                                cellspacing="0">
                                                                <tbody>
                                                                  <tr>
                                                                    <td colspan="4" className="
                                                                                    py-05
                                                                                    px-1
                                                                                    border-bottom border-dark
                                                                                    "> Flight Inclusions </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td className="
                                                                                    py-05
                                                                                    px-1
                                                                                    bg-gray
                                                                                    text-dark
                                                                                    "> Baggage </td>
                                                                    <td className="
                                                                                    py-05
                                                                                    px-1
                                                                                    bg-gray
                                                                                    text-dark
                                                                                    "> Adult </td>
                                                                    <td className="
                                                                                    py-05
                                                                                    px-1
                                                                                    bg-gray
                                                                                    text-dark
                                                                                    "> Child </td>
                                                                    <td className="
                                                                                    py-05
                                                                                    px-1
                                                                                    bg-gray
                                                                                    text-dark
                                                                                    "> Infant </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      className="py-05 px-1 text-dark">
                                                                      Cabin Baggage </td>
                                                                    <td
                                                                      className="py-05 px-1 text-dark">
                                                                      7 Kg </td>
                                                                    <td
                                                                      className="py-05 px-1 text-dark">
                                                                      7 Kg </td>
                                                                    <td
                                                                      className="py-05 px-1 text-dark">
                                                                      7 Kg </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      className="pb-05 px-1 text-dark">
                                                                      Check-in Baggage </td>
                                                                    <td
                                                                      className="pb-05 px-1 text-dark">
                                                                      15 Kg </td>
                                                                    <td
                                                                      className="pb-05 px-1 text-dark">
                                                                      15 Kg </td>
                                                                    <td
                                                                      className="pb-05 px-1 text-dark">
                                                                      0 Kg </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td colspan="4" className="
                                                                                    pt-1
                                                                                    px-1
                                                                                    pb-1
                                                                                    border-top border-dark
                                                                                    "><span className="
                                                                                    font-weight-bold font-md
                                                                                    ">* Flight inclusions are subject
                                                                        to change with
                                                                        Airlines</span></td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <table className="tickettable" role="presentation" border="0" cellpadding="1"
                                                cellspacing="0">
                                                <tbody>
                                                  <tr>
                                                    <td>&nbsp;</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="tickettable" role="presentation" border="0" cellpadding="1" cellspacing="0">
                                        <tbody>
                                          <tr>
                                            <td>&nbsp;</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="tickettable" role="presentation" border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <table className="tickettable" role="presentation" border="0" cellpadding="0"
                                                cellspacing="0">
                                                <tbody>
                                                  <tr>
                                                    <td width="200" className="font-weight-bold"
                                                      style={{ fontSize: 16 }}> Important Information
                                                    </td>
                                                    <td className="pt-1">
                                                      <p className="border-top border-dark"> &nbsp; </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <ul className="font-weight-bold" style={{ fontSize: 14 }}>
                                                <p>1. This ticket is Non Refundable &amp; Non Changeable
                                                </p>
                                                <p>2. All Guests, including children and infants, must
                                                  present valid identification at check-in.</p>
                                                <p>3. As per government directives, Web Check-in is
                                                  mandatory for all passengers before the scheduled
                                                  departure of their domestic flight. Charges apply*</p>
                                                <p>4. Check-in begins 3 hours prior to the flight for
                                                  seat assignment and closes 45 minutes prior to the
                                                  scheduled departure.</p>
                                                <p>5. Charged fare is totally agreed between "BUYER &amp;
                                                  SELLER", any issues related to fares thereafter will
                                                  not be entertained.</p>
                                                <p>6. We are not responsible for any Flight
                                                  delay/Cancellation from airline's end. kindly contact
                                                  the airline at least 24 hrs before to reconfirm your
                                                  flight detail giving reference of Airline PNR Number.
                                                  For any schedule change, flight cancelled &amp;
                                                  terminal related issues.</p>
                                              </ul>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tickets;
