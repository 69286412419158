import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UserService from "../services/user.service";
const MyLedger = () => {
  const [bookingData, setbookingData] = useState([]);
  const [page,setPage] = useState(1);
  const [pageArr,setPageArr] = useState([]);
  useEffect(() => {
		var value = page;
		UserService.myLedger(value)
			.then((res) => {
				if (res.data.status == 1) {
         let PagArr = [];
          for (let index = 0; index < res.data.data.last_page; index++) {
            PagArr.push(index);
            
          }
          setPageArr(PagArr);
					setbookingData(res.data.data.data)
				} else {
					setbookingData("")
				}
			})
			.catch((err) => {
        console.log(err)
				const { message } = err.response.data;

			});
	}, [,page]);
 const getStatus = (key) =>{
    switch (key) {
      case 2:
        return 'Pending' 
        break;
      case 1:
          return 'Accepted' 
          break;
      case 3:
            return 'Rejected' 
            break;
    
      default:
        break;
    }
  }
  return (
    <>
      <section className="sptb">
        <div className="container">
          <div className="section-title center-block text-center">
            <span className="heading-style" style={{color:"rgb(20, 92, 142)"}}>My Ledger</span>
          
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                
                  <div className="table-responsive bg-white">
                    <table className="table table-bordered border-top table-stripped mb-0 text-nowrap">
                      <thead>
                        <tr>
                          <th className="border-bottom-0 py-3">S.No.</th>
                          <th className="border-bottom-0 py-3">Date Time</th>
                          <th className="border-bottom-0 py-3">Type</th>
                          <th className="border-bottom-0 py-3">Debit</th>
                          <th className="border-bottom-0 py-3">Credit</th>
                          <th className="border-bottom-0 py-3">Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                      {bookingData?.map((item,index)=>{
                        return(
                        <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.created}</td>
                        <td>{item.type}</td>
                        <td>{item.debit}</td>
                        <td>{item.credit}</td>
                        <td>{item.runningbalace}</td>
                        </tr>
                       )})}
                      </tbody>
                    </table>
                  </div>
                  <ul className="pagination ">
											<li className={`page-item ${page == 1 ? "page-prev disabled" : ""}`}>
												<a className="page-link" onClick={()=>setPage(page-1)} tabindex="-1">Prev</a>
											</li>
                      {pageArr?.map((item,index)=>{   
                          return(
											<li key={index} className={`page-item ${page == index+1 ? "active" : ""}`}><a className="page-link" onClick={()=>setPage(index+1)}>{index+1}</a></li>
                      )})}
										
											<li className={`page-item ${page == pageArr.length ? "page-next disabled" : ""}`}>
												<a className="page-link" onClick={()=>setPage(page+1)}>Next</a>
											</li>
										</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyLedger;
