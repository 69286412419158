export const BASE_API = "https://fdworld.in/user/api/webservices";
export const MOBILE_REGEX = /^[0-9]{10}$/;

export const PASSWORDS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%^&*?])[A-Za-z\d#$@!%^&*?]{8,}$/g;

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NOSPACE_REGEX = /^(?!\s+$).*/;

export const NOSPACE = "Spaces are not allowed";

export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const MULTIPLESPACE = /  +/g;

export const NODIGITREGEX = /^([^0-9]*)$/
