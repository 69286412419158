import React from "react";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import "./login.css";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { RotatingLines } from 'react-loader-spinner'
const Login = () => {
  const navigate = useNavigate();
  const initialValues = { email: "", password: "" };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().max(255).required("Email is required").trim(),
    password: Yup.string()
      .min(2, "Too short")
      .max(50, "Too Long")
      .required("Password is required")
      .trim(),
  });

  const onSubmit = async (values) => {
   
    try {
    	
      const response = await AuthService.login(values);
      
      const {  status, message } = response.data;
      // const { token_id } = response.data.data;
     
      if (status==1) {
      
        localStorage.setItem("token", response.data.data.token);
        toast.info(message);
        navigate("/home", { replace: true });
      } else {
       
        toast.info(message);
      }
    } catch (error) {
      const { message } = error.response.data;
      console.log(error);
      toast.error(message);
    }
  };

  return (
    <>
   
      <section
        className="bannerimg cover-image bg-background3"
        data-image-src="https://qcbiztech.in/fdworld/public/web/images/banners/slide-banner.jpg"
      >
        <div className="header-text mb-0">
          <div className="container">
            <div className="row text-white">
              <div className="col">
                <h1 className="">Login</h1>
              </div>
              <div className="col col-auto">
                <ol className="breadcrumb text-center">
                  <li className="breadcrumb-item">
                    <a>Home</a>
                  </li>

                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    Login
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sptb">
        <div className="container customerpage">
          <div className="row">
            <div className="single-page">
              <div className="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
                <div className="wrapper wrapper2 text-left">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="card-body"
                        tabIndex="500"
                      >
                        <h4 className="font-weight-semibold2 pb-4">
                          Sign In Account
                        </h4>
                        <div className="mail">
                          <input
                            placeholder="Email Address"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <p className="text-danger">
                            {errors.email && touched.email && errors.email}
                          </p>

                          <label>Mail or Username</label>
                        </div>
                        <div className="passwd">
                          <input
                            placeholder="Password"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <p className="text-danger">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>

                          <label>Password</label>
                        </div>
                        <div className="submit">
                          <button
                            className="btn btn-primary btn-block"
                            disabled={isSubmitting}
                          >
                            Sign In
                          </button>
                        </div>
                        <div className="row">
                          <div className="col">
                            <p className="text-dark mb-0 fs-12">
                              Don't have account?
                              <Link to={"/register"}><a className="text-primary ml-1">Sign UP</a></Link>
                            </p>
                          </div>
                          <div className="col col-auto">
                            <p className="mb-0 mt-0 fs-12">
                              <a>Forgot Password</a>
                            </p>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>

                  {/* <hr className="divider" />
                  <div className="card-body text-center">
                    <ul className="mb-0 login-social-icons">
                      <li className="btn-facebook">
                        <a className="social-icon">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="btn-twitter">
                        <a className="social-icon">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li className="btn-google">
                        <a className="social-icon">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
