import axios from "axios";
import { BASE_API } from "../utils/Constants";

const API_URL = BASE_API;

const authHeader = () => {
  const token = localStorage.getItem("token");
console.log(token,"tokentoken");
  if (token) {
    return { Authorization: "Bearer " + token };
  }
  return {};
};

const UserService = {
  searchFlights(values) {
    return axios.post(API_URL + "/searchSectors", values, { headers: authHeader() });
  },
  bookingDetails(values) {
    return axios.post(API_URL + "/bookingDetails", values, { headers: authHeader() });
  },
  concertDetails(values) {
    return axios.post(API_URL + "/concertDetails", values, { headers: authHeader() });
  },
  submitBooking(values) {
    return axios.post(API_URL + "/submitBooking", values, { headers: authHeader() });
  },
  submitConcertBooking(values) {
    return axios.post(API_URL + "/submitConcertBooking", values, { headers: authHeader() });
  },
  paymentRequest(values) {
    return axios.post(API_URL + "/paymentRequest", values, { headers: authHeader() });
  },
  changePassword(values) {
    return axios.post(API_URL + "/changepassword", values, { headers: authHeader() });
  },
  myBookings(value) {
    return axios.get(API_URL + "/myBookings?page="+value, { headers: authHeader() });
  },
  myConcertBookings(value) {
    return axios.get(API_URL + "/myConcertBookings?page="+value, { headers: authHeader() });
  },
  getUserDetail(value) {
    return axios.get(API_URL + "/getUserDetail", { headers: authHeader() });
  },
  getBankDetail(value) {
    return axios.get(API_URL + "/getBankDetail", { headers: authHeader() });
  },
  ticketDetails(values) {
    return axios.post(API_URL + "/ticketDetails", values, { headers: authHeader() });
  },
  myPayments(value) {
    return axios.get(API_URL + "/myPayments?page="+value, { headers: authHeader() });
  },
  myLedger(value) {
    return axios.get(API_URL + "/myLedger?page="+value, { headers: authHeader() });
  },

 
};

export default UserService;
